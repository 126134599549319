import React from "react";
import GamePlay from "./screens/GamePlay";
import History from "./screens/History";
import Home from "./screens/Home";
import AttemptDetail from "./screens/AttemptDetail";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import "./App.css";

export function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const Stack = createNativeStackNavigator();

export default function App() {
  const isAuthed = !!getCookie("cred");

  return (
    <NavigationContainer>
      <Stack.Navigator>
        {!isAuthed && <Stack.Screen name="Home" component={Home} />}
        <Stack.Screen name="Groove" component={GamePlay} />
        <Stack.Screen name="History" component={History} />
        <Stack.Screen name="AttemptDetail" component={AttemptDetail} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
