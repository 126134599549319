import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Text } from "@rneui/themed";
import { Dimensions } from "react-native";
import Canvas from "react-native-canvas";
const screenWidth = Dimensions.get("window").width;

const getInterval = (arr) => {
  return arr[arr.length - 1] - arr[0];
};

const getLast = (arr) => arr[arr.length - 1];

export default function StatsCanvas({ stats }) {
  const ref = React.useRef(null);

  const calculate_diffs = (a, b) => {
    let res = [];
    a.forEach((v, i) => {
      if (i > 0) {
        res.push(v - b[i]);
      }
    });
    return res;
  };

  let refs = stats.song;
  let actuals = stats.actuals;

  const diffs =
    actuals.length == refs.length ? calculate_diffs(actuals, refs) : null;

  React.useEffect(() => {
    if (!ref.current) return;
    const ctx = ref.current.getContext("2d");
    if (!ctx) return;
    const canvasWidth = 600 - 10;
    let longerInterval =
      getInterval(actuals) >= getInterval(refs) ? "actuals" : "refs";
    const ratio =
      longerInterval === "actuals"
        ? actuals.map((a) => a / actuals[actuals.length - 1])
        : refs.map((r) => r / refs[refs.length - 1]);

    if (longerInterval === "actuals") {
      for (let r of refs) {
        ctx.fillRect(
          (r / actuals[actuals.length - 1]) * canvasWidth,
          10,
          5,
          20
        );
      }
      for (let i in ratio) {
        ctx.fillRect(ratio[i] * canvasWidth, 40, 5, 20);
        if (actuals.length === refs.length) {
          if (i == 0) continue;
          ctx.fillText(diffs[i - 1], ratio[i] * canvasWidth - 6, 70);
        }
      }
    } else {
      for (let r of ratio) {
        ctx.fillRect(r * canvasWidth, 10, 5, 20);
      }
      console.log({diffs}, {actuals}, {refs})
      for (let i in actuals) {
        ctx.fillRect(
          (actuals[i] / refs[refs.length - 1]) * canvasWidth,
          40,
          5,
          20
        );
        if (actuals.length === refs.length) {
          if (i == 0) continue;
          ctx.fillText(diffs[i-1], actuals[i] * (canvasWidth - 6) / refs[refs.length - 1], 70);
        }
      }
    }
  }, [ref]);

  return (
    <View style={styles.container}>
      <Text h4 style={{ ...styles.textStyle, margin: 20, marginBottom: 0 }}>
        {actuals.length < refs.length
          ? "You missed a beat"
          : actuals.length > refs.length
          ? "You have an extra beat"
          : null}
      </Text>
      {diffs && (
        <Text h5 style={{ ...styles.textStyle, margin: 5 }}>
          Average error is{" "}
          {Math.floor(
            diffs.reduce((acc, curr) => acc + Math.abs(curr), 0) / diffs.length
          )}{" "}
          ms
        </Text>
      )}
      {Platform.OS === "web" ? (
        <canvas
          ref={ref}
          style={{ backgroundColor: "white", border: "10px white solid" }}
          width={"600"}
          height={"300"}
        />
      ) : (
        <Canvas
          ref={ref}
          style={{ background: "white" }}
          width={"600"}
          height={"300"}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    alignItems: "center",
  },
  textStyle: {
    color: "white",
  },
});
