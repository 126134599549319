import { StatusBar } from "expo-status-bar";
import React from "react";
import { StyleSheet, View, Platform, Dimensions } from "react-native";
import { Button, withTheme, Text, Switch, Input } from "@rneui/themed";
import { Audio } from "expo-av";
import GamePlayResult from "./GamePlayResult";
import LottieView from "../configs/LottieComponent";
import { BACKEND_URL, BACKEND_WS } from "./utils/constants";
import { getCookie } from "../App";
import Slider from "@react-native-community/slider";

const screenWidth = Dimensions.get("window").width;
// score history

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const rest = {
  a: 1,
  b: 2,
  c: 4,
  d: 8,
  e: 16,
};

function deleteCookie(name, path, domain) {
  var pathStr = path ? "; path=" + path : "";
  var domainStr = domain ? "; domain=" + domain : "";
  document.cookie =
    name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + pathStr + domainStr;
}

const tssToDeltas = (tss) =>
  tss.map((ts, index) => (index > 0 ? ts - tss[index - 1] : ts));

export const songToTss = (song, bpm) => {
  const notes = song.notes;
  const beat_unit = song.beat_unit;
  const delay = (60 / bpm) * 1000;
  let ts = 0;
  let res = [];
  for (let note of notes) {
    if (note in rest) {
      ts += (beat_unit / rest[note]) * delay;
    } else {
      res.push(Math.round(ts));
      if (note == "g") {
        note = 16;
      }
      ts += (beat_unit / Math.round(note)) * delay;
    }
  }
  return res;
};

const getNoteCount = (notes) => {
  return notes.split("").filter((n) => !(n in rest)).length;
};

export default function GamePlay({ navigation }) {
  const [songs, setSongs] = React.useState([]);
  const [ws, setWebSocket] = React.useState(null);
  const [players, setPlayers] = React.useState([]);
  const [playerStatuses, setPlayersStatuses] = React.useState([]);
  const [bpm, setBpm] = React.useState(120);
  const [isMetronomeOn, setIsMetronomeOn] = React.useState(false);
  const [metronomeId, setMetronomeId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [startTime, setStartTime] = React.useState(null);
  const [room, setRoom] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [timestamps, setTimeStamps] = React.useState([]);
  const [displayPanel, setDisplayPanel] = React.useState(false);
  const [isComplete, setIsComplete] = React.useState(false);
  const [multiResults, setMultiResults] = React.useState([]);
  const [sound, setSound] = React.useState();
  const [pSound, setPSound] = React.useState();
  const [mSound, setMSound] = React.useState();
  const [isSoundOn, setIsSoundOn] = React.useState(true);
  const lottieRef = React.useRef(null);
  const [isSpacebarPressed, setIsSpacebarPressed] = React.useState(false);
  const [isListen, setIsListen] = React.useState(false);

  const inactivityTimer = React.useRef(null);
  const refRoomName = React.useRef(null);

  const logout = () => {
    deleteCookie("cred");
    location.reload();
  };

  const handlePlayAnimation = () => {
    lottieRef.current.reset();
    lottieRef.current.play();
  };

  React.useEffect(() => {
    if (!displayPanel) {
      setIsComplete(false);
      setTimeStamps([]);
    }
  }, [displayPanel]);

  React.useEffect(() => {
    const loadSounds = async () => {
      const { sound } = await Audio.Sound.createAsync(
        require("../assets/rimshot-input.mp3")
      );

      setSound(sound);
      const p = await Audio.Sound.createAsync(require("../assets/rimshot.mp3"));
      setPSound(p.sound);
      const m = await Audio.Sound.createAsync(
        require("../assets/metronome.mp3")
      );
      setMSound(m.sound);
    };

    loadSounds();

    return () => {
      if (sound) {
        sound.unloadAsync(); // Unload the sound when the component unmounts
      }
      if (pSound) {
        pSound.unloadAsync();
      }
      if (mSound) {
        mSound.unloadAsync();
      }
    };
  }, []);

  const joinRoom = () => {
    // create a socket
    const websocket = new WebSocket(
      `${BACKEND_WS}/socket?room=${refRoomName.current.value}&name=${getCookie(
        "name"
      )}`
    );
    setWebSocket(websocket);
    setRoom(refRoomName.current.value);
    return websocket;
  };

  const leaveRoom = () => {
    setWebSocket(null);
    setIsReady(false);
    ws.close();
    setPlayers([]);
    setRoom(null);
    setSelectedIndex(0);
  };

  if (ws) {
    ws.onmessage = function (event) {
      let message = JSON.parse(event.data);
      switch (message.type) {
        case "notif":
          setPlayers(message.players);
          if (
            message.players.filter((e) => e === getCookie("name")).length > 1
          ) {
            leaveRoom();
          }
          break;
        case "ready":
          setPlayersStatuses(message.players);
          if (message.song_id) {
            setSelectedIndex(message.song_id % songs.length);
          }
          break;
        case "start":
          startGroupGame();
          setMultiResults([]);
          break;
        case "complete":
          setPlayersStatuses([]);
          const res = Object.entries(message.results).map(
            ([user, timestamps]) => ({
              user,
              bpm: timestamps.split(" ").map(Number)[0],
              timestamps: timestamps.split(" ").map(Number).slice(1),
            })
          );
          setMultiResults(res);
          setDisplayPanel(true);
          break;
      }
    };
  }

  const toggleMetronome = () => {
    setIsMetronomeOn((prev) => !prev);
  };

  React.useEffect(() => {
    if (isComplete) {
      metronomeId && clearInterval(metronomeId);
      const count = getNoteCount(songs[selectedIndex].notes);
      submitScore(timestamps, timestamps.length - count);
      setIsReady(false);
      if (timestamps.length != count) {
        alert(
          timestamps.length > count ? "you had more notes" : "you missed a note"
        );
      }

      setStartTime(null);
    }
  }, [isComplete]);

  React.useEffect(() => {
    // Event listeners to detect when spacebar is pressed and released
    const handleKeyDown = (e) => {
      if (e.code === "Space") {
        setIsSpacebarPressed(true);
        playButtonHandler(startTime);
      }
    };

    const handleKeyUp = (e) => {
      if (e.code === "Space") {
        setIsSpacebarPressed(false);
      }
    };

    // Add the event listeners
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup event listeners when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [startTime, sound]);

  const petDog = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }

    inactivityTimer.current = setTimeout(() => {
      setIsComplete(true);
    }, 2000);
  };

  async function playButtonHandler(inProgress) {
    setTimeStamps((prev) => [...prev, Date.now()]);

    if (sound && isSoundOn) {
      await playSound(sound);
    }
    handlePlayAnimation();

    if (inProgress) {
      petDog();
    }
  }

  async function playSound(sound, vol = 1) {
    await sound.setPositionAsync(0); // Reset the position to the beginning
    await sound.setVolumeAsync(vol);
    await sound.playAsync();
  }

  function submitScore(timestamps, countDiff) {
    fetch(`${BACKEND_URL}/results`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        song_name: songs[selectedIndex].name,
        bpm,
        note_times_ms: timestamps,
        room,
        count_diff: countDiff,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (players && players.length > 0) {
          ws.send(
            JSON.stringify({
              type: "complete",
              room: room,
              // result: timestamps,
            })
          );
        } else {
          setTimeout(() => {
            setDisplayPanel(true);
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  React.useEffect(() => {
    async function fetchData() {
      fetch(`${BACKEND_URL}/songs`, { credentials: "include" })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setSongs(data.res);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    fetchData();
  }, []);

  React.useEffect(() => {
    if (songs[selectedIndex]) {
      setBpm(songs[selectedIndex].bpm_recommended);
    }
  }, [selectedIndex]);

  async function readyGroupGame() {
    setIsReady(true);
    ws.send(
      JSON.stringify({
        type: "ready",
        room: room,
        bpm: bpm,
      })
    );
  }

  async function startGroupGame() {
    if (isMetronomeOn) {
      const id = setInterval(() => playSound(mSound, 0.5), (60 / bpm) * 1000);
      setMetronomeId(id);
    }
    setTimeStamps([]);
    setIsComplete(false);
    setStartTime(Date.now());
    playSong();
  }

  async function startGame() {
    setIsListen(true);
    if (isMetronomeOn) {
      const id = setInterval(() => playSound(mSound, 0.5), (60 / bpm) * 1000);
      setMetronomeId(id);
    }
    setTimeStamps([]);
    setIsComplete(false);
    setStartTime(Date.now());
    await playSong();
    setIsListen(false);
  }

  async function playSong() {
    const deltas = tssToDeltas(songToTss(songs[selectedIndex], bpm));
    for (let i = 0; i <= deltas.length; i++) {
      await sleep(deltas[i]);
      pSound.playFromPositionAsync(0);
    }
  }

  const isInRoom = players && players.length > 0;

  return (
    <>
      <View>
        <Button onPress={logout}>Log out</Button>
      </View>
      <View style={styles.leftColumn}>
        <Text style={styles.textStyle}>{getCookie("name") ?? "guest"}</Text>
      </View>
      <View style={styles.rightColumn}>
        {players &&
          players.map((p) => (
            <>
              <Text style={styles.textStyle}>
                {playerStatuses.includes(p) ? (
                  <Text>🟩 </Text>
                ) : (
                  <Text>🟥 </Text>
                )}
                {p}
              </Text>
            </>
          ))}
      </View>
      <View style={{...styles.container, zIndex: -1}}>
        <View
          style={{
            height: "45%",
            minHeight: 320,
            width: "100%",
            alignItems: "center",
          }}
        >
          {startTime ? (
            isListen ? (
              <Text style={styles.cueStyle}>Listen</Text>
            ) : (
              <Text style={styles.cueStyle}>Play</Text>
            )
          ) : null}
          {isComplete && (
            <LottieView
              source={require("../assets/animations/blast.json")}
              loop
              autoPlay
              style={styles.lottie}
            />
          )}
          <LottieView
            ref={lottieRef}
            source={require("../assets/animations/duck.json")}
            loop={false}
            resizeMode="cover"
            style={[styles.lottie, styles.lottieOverlay]}
          />
        </View>
        {!startTime && (
          <View style={{ flexDirection: "row" }}>
            <Input
              placeholder={room ? room : "room name"}
              disabled={isInRoom}
              containerStyle={{ width: 120 }}
              inputStyle={{ ...styles.textStyle, width: 50 }}
              ref={refRoomName}
              returnKeyType="done"
              onSubmitEditing={joinRoom}
              onChangeText={(value) => {
                if (refRoomName.current) {
                  refRoomName.current.value = value;
                }
              }}
            />
            {isInRoom ? (
              <Button buttonStyle={{ marginLeft: 10 }} onPress={leaveRoom}>
                Leave room
              </Button>
            ) : (
              <Button buttonStyle={{ marginLeft: 10 }} onPress={joinRoom}>
                Join room
              </Button>
            )}
          </View>
        )}
        {!startTime && !isInRoom && songs[selectedIndex] && (
          <>
            <View style={{ flexDirection: "row" }}>
              <View>
                <Text style={styles.textStyle}>BPM: {bpm}</Text>
                <Slider
                  style={{ width: 200, height: 50 }}
                  minimumValue={40}
                  maximumValue={208}
                  minimumTrackTintColor="#FFFFFF"
                  maximumTrackTintColor="#000000"
                  thumbTintColor="#7d9db0"
                  step={
                    bpm < 60
                      ? 2
                      : bpm < 72
                      ? 3
                      : bpm < 120
                      ? 4
                      : bpm < 144
                      ? 6
                      : 8
                  }
                  value={bpm}
                  onValueChange={setBpm}
                />
              </View>
              <View style={{ marginLeft: 20 }}>
                <Text style={{ ...styles.textStyle, marginBottom: 15 }}>
                  Metronome:
                </Text>
                <Switch
                  value={isMetronomeOn}
                  onValueChange={(e) => toggleMetronome(e)}
                />
              </View>
              <View style={{ marginLeft: 20 }}>
                <Text style={{ ...styles.textStyle, marginBottom: 15 }}>
                  Sound:
                </Text>
                <Switch
                  value={isSoundOn}
                  onValueChange={() => setIsSoundOn((prev) => !prev)}
                />
              </View>
            </View>
            {!room && (
              <View style={{ flexDirection: "row" }}>
                <Button
                  title={"<"}
                  buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
                  onPress={() =>
                    setSelectedIndex((prev) =>
                      prev === 0 ? songs.length - 1 : prev - 1
                    )
                  }
                />
                <Text
                  style={{
                    ...styles.textStyle,
                    fontSize: 18,
                    lineHeight: 36,
                    width: 200,
                    textAlign: "center",
                  }}
                >
                  {songs[selectedIndex].name}
                </Text>
                <Button
                  buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
                  title={">"}
                  onPress={() =>
                    setSelectedIndex((prev) =>
                      prev === songs.length - 1 ? 0 : prev + 1
                    )
                  }
                />
                <Button
                  buttonStyle={{
                    backgroundColor: "rgba(31, 40, 49, 1)",
                    marginLeft: 5,
                  }}
                  onPress={() => playSong()}
                  title={"Preview"}
                />
              </View>
            )}
          </>
        )}
        <View style={{ alignItems: "center" }}>
          <View style={{ margin: 10, flexDirection: "row" }}>
            {!isReady && players.length > 0 ? (
              <Button
                title="Ready"
                onPress={() => readyGroupGame()}
                buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
                containerStyle={{
                  width: 100,
                  marginHorizontal: 5,
                }}
              />
            ) : (
              !isReady &&
              !startTime && (
                <>
                  <Button
                    title="Start"
                    onPress={() => startGame()}
                    buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
                    containerStyle={{
                      width: 100,
                      marginHorizontal: 5,
                    }}
                  />
                </>
              )
            )}
          </View>

          <Button
            title="Tap"
            onPress={() => playButtonHandler(startTime)}
            buttonStyle={{
              backgroundColor: isSpacebarPressed
                ? "rgba(32, 137, 220, 0.5)"
                : "rgb(32, 137, 220)",
            }}
            containerStyle={{
              width: 100,
              marginHorizontal: 5,
            }}
          />
          <Button
            title="Navigate to history"
            type="clear"
            titleStyle={{ color: "rgba(255, 255, 255, 0.8)" }}
            onPress={() => {
              navigation.navigate("History");
            }}
          />
          {isComplete && timestamps && (
            <GamePlayResult
              timestamps={timestamps}
              records={multiResults}
              song={songs[selectedIndex]}
              displayPanel={displayPanel}
              setDisplayPanel={setDisplayPanel}
              bpm={bpm}
            />
          )}
        </View>

        <StatusBar style="auto" />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  leftColumn: {
    position: "absolute",
    top: 50,
    left: 0,
    zIndex: 2,
    backgroundColor: "#313F49",
    alignItems: "flex-start",
  },
  rightColumn: {
    position: "absolute",
    top: 50,
    right: 0,
    zIndex: 2,
    backgroundColor: "#313F49",
    alignItems: "flex-end",
  },
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    alignItems: "center",
    justifyContent: "center",
  },
  textStyle: {
    color: "white",
  },
  cueStyle: {
    color: "white",
    fontSize: 18,
  },
  lottie: {
    position: "absolute",
    height: 400,
  },
  lottieOverlay: {
    zIndex: 1, // This will make the second LottieView appear on top
  },
});
