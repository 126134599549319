import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Text } from "@rneui/themed";
import { Dimensions } from "react-native";
import Canvas from "react-native-canvas";
const screenWidth = Dimensions.get("window").width;

const getInterval = (arr) => {
  return arr[arr.length - 1] - arr[0];
};

const getLast = (arr) => arr[arr.length - 1];

export default function MultiStatsCanvas({ multiStats }) {
  const ref = React.useRef(null);

  const calcAbsAvg = (a) =>
    a.reduce((acc, curr) => acc + Math.abs(curr), 0) / a.length;
  multiStats = multiStats
    .map((ms) => ({
      ...ms,
      avg:
        ms.stats.actuals.length === ms.stats.song.length
          ? calcAbsAvg(ms.stats.errors.slice(1))
          : Infinity,
    }))
    .sort((a, b) => a.avg - b.avg);
  React.useEffect(() => {
    if (!ref.current) return;
    const ctx = ref.current.getContext("2d");
    let y = 10;

    if (!ctx) return;
    const canvasWidth = 500 - 10;
    ctx.font = "10px Arial";

    let longestUser = multiStats.reduce(
      (acc, curr) => {
        let interval = getInterval(curr.timestamps);
        let max_interval = acc[1];
        if (max_interval < interval) {
          acc = [curr, interval];
        }
        return acc;
      },
      [multiStats[0], getInterval(multiStats[0].timestamps)]
    )[0];

    let baseRatio = [];
    if (longestUser.stats.actuals.length > longestUser.stats.song.length) {
      baseRatio = longestUser.stats.actuals.map(
        (a) =>
          a / longestUser.stats.actuals[longestUser.stats.actuals.length - 1]
      );
    } else {
      baseRatio = longestUser.stats.song.map(
        (s) => s / longestUser.stats.song[longestUser.stats.song.length - 1]
      );
    }

    const refPos =
      getInterval(longestUser.stats.actuals) >
      getInterval(longestUser.stats.song)
        ? longestUser.stats.song.map(
            (s) => (s / getLast(longestUser.stats.actuals)) * canvasWidth
          )
        : longestUser.stats.song.map(
            (s) => (s / getLast(longestUser.stats.song)) * canvasWidth
          );
    let ratio = getLast(longestUser.stats.song) / getLast(refPos);
    for (let r of refPos) {
      ctx.fillStyle = "grey";
      ctx.fillRect(r, y, 5, 20);
    }
    for (let r of refPos) {
      ctx.strokeStyle = "grey";
      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.moveTo(r + 3, 0);
      ctx.lineTo(r + 3, 400);
      ctx.stroke();
      ctx.setLineDash([]);
    }
    for (let item of multiStats) {
      const longer =
        item.stats.actuals.length >= item.stats.song.length
          ? "actuals"
          : "refs";

      for (let i in item.stats.actuals) {
        ctx.fillStyle = "black";
        ctx.fillRect(item.stats.actuals[i] / ratio, y + 30, 5, 20);
        if (item.stats.actuals.length === item.stats.song.length)
          ctx.fillText(
            item.stats.errors[i],
            item.stats.actuals[i] / ratio - 6,
            y + 60
          );
      }
      y = y + 40;
    }
  }, [ref]);

  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <Text style={{ marginBottom: 20, fontWeight: "bold", color: "white" }}>
          Ref
        </Text>
        {multiStats.map((ms) => (
          <Text style={{ marginBottom: 20, color: "white" }}>{ms.user}</Text>
        ))}
      </View>
      <View style={styles.canvas}>
        {Platform.OS === "web" ? (
          <canvas
            ref={ref}
            style={{ backgroundColor: "white" }}
            width={"500"}
            height={"300"}
          />
        ) : (
          <Canvas
            ref={ref}
            style={{ background: "white" }}
            width={"500"}
            height={"300"}
          />
        )}
      </View>
      <View style={{ ...styles.column, alignItems: "flex-start" }}>
        <Text
          style={{
            marginBottom: 15,
            fontWeight: "bold",
            color: "white",
          }}
        >
          Avg Error
        </Text>
        {multiStats.map((ms) => (
          <Text style={{ marginBottom: 20, color: "white" }}>
            {ms.stats.actuals.length > ms.stats.song.length
              ? "N/A (Extra)"
              : ms.stats.actuals.length < ms.stats.song.length
              ? "N/A (Missed)"
              : `${Math.floor(ms.avg)} ms`}
          </Text>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    flexDirection: "row",
  },
  column: {
    padding: 10,
    alignItems: "flex-end",
    flex: 0.5,
  },
  textStyle: {
    color: "white",
  },
});
