import React from "react";
import { StyleSheet, View, Alert } from "react-native";
import { ListItem, Text, Input, Button } from "@rneui/themed";
import { Dimensions } from "react-native";
import { BACKEND_URL } from "./utils/constants";
const screenWidth = Dimensions.get("window").width;

export default function Home({ navigation }) {
  const [isSignUp, setIsSignup] = React.useState(false);
  const [isVisitor, setIsVisitor] = React.useState(false);
  const refUsername = React.useRef();
  const refPassword = React.useRef();

  const submit = () => {
    if (isVisitor) {
      fetch(`${BACKEND_URL}/users?isVisitor=true`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          name: refUsername.current.value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          document.cookie = `name=${refUsername.current.value}`;
          navigation.navigate("Groove");
        })
        .catch((err) => {
          setIsSignup(false);
          console.log(err.message);
        });
    } else {
      fetch(isSignUp ? `${BACKEND_URL}/users` : `${BACKEND_URL}/login`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          name: refUsername.current.value,
          passwd: refPassword.current.value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          document.cookie = `name=${refUsername.current.value};max-age=31536000`;
          navigation.navigate("Groove");
        })
        .catch((err) => {
          setIsSignup(false);
          console.log(err.message);
        });
    }
  };

  return (
    <View style={styles.container}>
      <Text h1 style={{ ...styles.textStyle, margin: 20 }}>
        Home
      </Text>
      {isVisitor ? (
        <View>
          <Text h3 style={{ ...styles.textStyle, margin: 20 }}>
            Visitor
          </Text>
          <Input
            placeholder="Username"
            inputStyle={styles.textStyle}
            ref={refUsername}
            returnKeyType="done"
            onSubmitEditing={submit}
            onChangeText={(value) => {
              if (refUsername.current) {
                refUsername.current.value = value;
              }
            }}
          />
        </View>
      ) : isSignUp ? (
        <View>
          <Text h3 style={{ ...styles.textStyle, margin: 20 }}>
            Sign up
          </Text>
          <Input
            placeholder="Username"
            inputStyle={styles.textStyle}
            ref={refUsername}
            onChangeText={(value) => {
              if (refUsername.current) {
                refUsername.current.value = value;
              }
            }}
          />
          <Input
            placeholder="Password"
            secureTextEntry={true}
            ref={refPassword}
            onChangeText={(value) => {
              if (refPassword.current) {
                refPassword.current.value = value;
              }
            }}
          />
          <Input
            placeholder="Confirm Password"
            returnKeyType="done"
            onSubmitEditing={submit}
            secureTextEntry={true}
          />
        </View>
      ) : (
        <View>
          <Text h3 style={{ ...styles.textStyle, margin: 20 }}>
            Sign in
          </Text>
          <Input
            placeholder="Username"
            inputStyle={styles.textStyle}
            ref={refUsername}
            onChangeText={(value) => {
              if (refUsername.current) {
                refUsername.current.value = value;
              }
            }}
          />
          <Input
            placeholder="Password"
            secureTextEntry={true}
            ref={refPassword}
            returnKeyType="done"
            onSubmitEditing={submit}
            onChangeText={(value) => {
              if (refPassword.current) {
                refPassword.current.value = value;
              }
            }}
          />
        </View>
      )}

      <View>
        <Button
          onPress={submit}
          containerStyle={{ marginBottom: 10 }}
          buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
        >
          Submit
        </Button>
        <Button
          onPress={() => {
            setIsVisitor(false);
            setIsSignup((prev) => !prev);
          }}
          type="outline"
          containerStyle={{ marginBottom: 10 }}
        >
          {isSignUp ? "Sign in" : "Sign up"}
        </Button>
        {!isVisitor && (
          <Button
            onPress={() => setIsVisitor((prev) => !prev)}
            type="outline"
            containerStyle={{ marginBottom: 10 }}
          >
            Visitor
          </Button>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    alignItems: "center",
  },
  textStyle: {
    color: "white",
  },
});
