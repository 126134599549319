import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Button, Text } from "@rneui/themed";
import { Audio } from "expo-av";
import { Dimensions } from "react-native";
import { BACKEND_URL } from "./utils/constants";
import Canvas from "react-native-canvas";
import Slider from "@react-native-community/slider";

const screenWidth = Dimensions.get("window").width;

const getLast = (arr) => arr[arr.length - 1];

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const tssToDeltas = (tss) =>
  tss.map((ts, index) => (index > 0 ? ts - tss[index - 1] : ts));

export default function AttemptDetail({ route, navigation }) {
  const { id } = route.params;
  const [tss, setTss] = React.useState(null);
  const [rSound, setRSound] = React.useState();
  const [pSound, setPSound] = React.useState();
  const [volumeRef, setVolumeRef] = React.useState(1.0);
  const [volumePlayer, setVolumePlayer] = React.useState(1.0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const loadSounds = async () => {
      const p = await Audio.Sound.createAsync(
        require("../assets/rimshot-input.mp3")
      );
      setPSound(p.sound);
      const r = await Audio.Sound.createAsync(require("../assets/rimshot.mp3"));
      setRSound(r.sound);
    };

    loadSounds();

    return () => {
      if (pSound) {
        pSound.unloadAsync();
      }
      if (rSound) {
        rSound.unloadAsync();
      }
    };
  }, []);

  async function playSong(instrument, deltas, volume) {
    instrument.setVolumeAsync(volume);
    for (let i = 0; i <= deltas.length; i++) {
      await sleep(deltas[i]);
      instrument.playFromPositionAsync(0);
    }
  }

  async function playTogether({ deltaRef, deltaPlayer }) {
    const isBoth = deltaRef && deltaPlayer;
    deltaRef && playSong(rSound, deltaRef, isBoth ? volumeRef : 1);
    deltaPlayer && playSong(pSound, deltaPlayer, isBoth ? volumePlayer : 1);
  }

  React.useEffect(() => {
    async function fetchData() {
      fetch(`${BACKEND_URL}/results/${id}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setTss(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    fetchData();
  }, []);
  React.useMemo(() => {
    if (ref.current && tss) {
      const cx = ref.current.getContext("2d");
      tss.errors = [];
      let { references, actuals } = tss;
      references = [0].concat(references);
      actuals = [0].concat(actuals);
      const largest = Math.max(getLast(references), getLast(actuals));

      for (let r of references) {
        cx.fillStyle = "grey";
        cx.fillRect((r / largest) * (400 - 5), 10, 5, 20);
      }
      for (let i in actuals) {
        cx.fillStyle = "black";
        cx.fillRect((actuals[i] / largest) * (400 - 5), 40, 5, 20);
        let delta = actuals[i] - references[i];
        tss.errors.push(delta);
        cx.fillText(delta, (actuals[i] / largest) * (400 - 5) - 5, 70);
      }
    }
  }, [ref, tss]);

  return (
    <View style={styles.container}>
      <Text h1 style={{ ...styles.textStyle, margin: 20 }}>
        AttemptDetail
      </Text>
      <View>
        {Platform.OS === "web" ? (
          <canvas
            ref={ref}
            style={{ backgroundColor: "white" }}
            width={"400"}
            height={"200"}
          />
        ) : (
          <Canvas
            ref={ref}
            style={{ background: "white" }}
            width={"400"}
            height={"200"}
          />
        )}
      </View>
      {tss && tss.errors && (
        <Text h5 style={{ ...styles.textStyle, margin: 10 }}>
          Average error for this attempt is{" "}
          {tss.errors.reduce((acc, curr) => acc + Math.abs(curr), 0) /
            tss.errors.length}
        </Text>
      )}
      <View style={{ marginTop: 10 }}>
        <Button
          onPress={() =>
            playTogether({ deltaRef: tssToDeltas(tss.references) })
          }
          containerStyle={{ marginBottom: 20 }}
          buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
        >
          Play Ref
        </Button>
        <Button
          onPress={() =>
            playTogether({ deltaPlayer: tssToDeltas(tss.actuals) })
          }
          containerStyle={{ marginBottom: 20 }}
          buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
        >
          Play Player
        </Button>
        <Text style={styles.text}>
          Ref's Volume: {volumeRef && +volumeRef.toFixed(2)}
        </Text>
        <Slider
          style={{ width: 200, height: 50 }}
          minimumValue={0}
          maximumValue={1}
          minimumTrackTintColor="#FFFFFF"
          maximumTrackTintColor="#000000"
          thumbTintColor="#7d9db0"
          value={volumeRef}
          onValueChange={setVolumeRef}
        />
        <Text style={styles.text}>
          Player's Volume: {volumePlayer && +volumePlayer.toFixed(2)}
        </Text>
        <Slider
          style={{ width: 200, height: 50 }}
          minimumValue={0}
          maximumValue={1}
          minimumTrackTintColor="#FFFFFF"
          maximumTrackTintColor="#000000"
          thumbTintColor="#7d9db0"
          value={volumePlayer}
          onValueChange={setVolumePlayer}
        />

        <Button
          onPress={() =>
            playTogether({
              deltaRef: tssToDeltas(tss.references),
              deltaPlayer: tssToDeltas(tss.actuals),
            })
          }
          buttonStyle={{ backgroundColor: "rgba(31, 40, 49, 1)" }}
        >
          Play Together
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    alignItems: "center",
  },
  textStyle: {
    color: "white",
  },
  text: {
    color: "white",
  },
});
