import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "@rneui/themed";
import { songToTss } from "./GamePlay";
import { Animated, Dimensions, Button } from "react-native";
import StatsCanvas from "./components/StatsCanvas";
import MultiStatsCanvas from "./components/MultiStatsCanvas";

const screenWidth = Dimensions.get("window").width;

const zip = (arr1, arr2) =>
  arr1.map((element, index) => [element, arr2[index]]);

const analyze = (ref_tss, in_tss) => ({
  errors: zip(ref_tss, in_tss).map(([elem1, elem2]) => elem2 - elem1),
  actuals: in_tss,
  song: ref_tss,
});

export default function GamePlayResult({
  song,
  timestamps,
  records = [],
  displayPanel,
  setDisplayPanel,
  bpm,
}) {
  const initial = new Animated.Value(Dimensions.get("window").height - 100);
  const [animation] = React.useState(initial);
  const normalizeTss = (timestamps) =>
    timestamps.map((ts) => ts - timestamps[0]);

  let stats = analyze(songToTss(song, bpm), normalizeTss(timestamps));

  let res = records.map((stats) => ({
    stats: analyze(songToTss(song, stats.bpm), normalizeTss(stats.timestamps)),
    ...stats,
  }));

  React.useEffect(() => {
    if (displayPanel) {
      slideUp();
    } else {
      slideDown();
    }
  }, [displayPanel]);

  const slideDown = () => {
    Animated.timing(animation, {
      toValue: initial,
      duration: 800,
      useNativeDriver: false,
    }).start();
  };

  const slideUp = () => {
    Animated.timing(animation, {
      toValue: 0,
      duration: 800,
      useNativeDriver: false,
    }).start();
  };

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Animated.View
        style={{
          position: "absolute",
          height: Dimensions.get("window").height + 100,
          width: screenWidth,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: "rgba(31, 40, 49, 1)",
          transform: [{ translateY: animation }],
        }}
      >
        <Text
          h4
          style={{
            ...styles.textStyle,
            textAlign: "center",
          }}
        >
          Review
        </Text>
        <Button title="Hide Panel" onPress={() => setDisplayPanel(false)} />
        {res.length ? (
          <MultiStatsCanvas multiStats={res} />
        ) : (
          <StatsCanvas stats={stats} />
        )}
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    alignItems: "center",
  },
  textStyle: {
    color: "white",
  },
});
