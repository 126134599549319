import React from "react";
import { StyleSheet, View, Picker } from "react-native";
import { ListItem, Text } from "@rneui/themed";
import { Dimensions, SafeAreaView, ScrollView, Platform } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { BACKEND_URL } from "./utils/constants";
const screenWidth = Dimensions.get("window").width;

export default function History({ navigation }) {
  const [records, setRecords] = React.useState([]);
  const [selectedSong, setSelectedSong] = React.useState("all");
  const [filteredRecords, setFilteredRecords] = React.useState([]);
  React.useEffect(() => {
    if (selectedSong == "all") setFilteredRecords(records);
    else setFilteredRecords(records.filter((x) => x.song_name == selectedSong));
  }, [selectedSong, records]);
  React.useEffect(() => {
    async function fetchData() {
      fetch(`${BACKEND_URL}/results`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setRecords(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    fetchData();
  }, []);

  const convertDate = (ts) => {
    const date = new Date(ts * 1000);
    const humanReadableDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return humanReadableDate;
  };

  const data = {
    labels: filteredRecords.map((r) => convertDate(r.created)),
    datasets: [
      {
        data: filteredRecords.map((r) => r.error),
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`, // optional
        strokeWidth: 2, // optional
      },
    ],
  };

  const songsSet = new Set(records.map((r) => r.song_name));
  const songs = [...songsSet];

  return (
    <View style={styles.container}>
      <Text h1 style={{ ...styles.textStyle, margin: 20 }}>
        History
      </Text>
      <Picker
        selectedValue={selectedSong}
        onValueChange={(v) => setSelectedSong(v)}
      >
        <Picker.Item label="all" value="all" />
        {songs.map((s) => (
          <Picker.Item label={s} value={s} />
        ))}
      </Picker>

      <ScrollView style={{ width: "100%", maxHeight: "100%" }}>
        {filteredRecords.reverse().map((item, i) => (
          <ListItem
            key={i}
            onPress={() => {
              navigation.navigate("AttemptDetail", { id: item.id });
            }}
            containerStyle={{
              backgroundColor: "rgba(40, 50, 60, 1)",
              marginBottom: "1%",
            }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ fontWeight: 700, color: "white" }}>
                Avg Error: {Math.floor(item.error)}ms Song: {item.song_name}{" "}
                BPM: {item.bpm}
              </ListItem.Title>
              <ListItem.Subtitle style={{ color: "white" }}>
                Date: {convertDate(item.created)}
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        ))}
      </ScrollView>
      {data.labels.length && (
        <LineChart
          data={data}
          width={screenWidth}
          height={220}
          chartConfig={{
            backgroundColor:
              Platform.OS === "web" ? "transparent" : "rgba(31, 40, 49, 1)",
            backgroundGradientFrom:
              Platform.OS === "web" ? "transparent" : "rgba(31, 40, 49, 1)",
            backgroundGradientTo:
              Platform.OS === "web" ? "transparent" : "rgba(31, 40, 49, 1)",
            decimalPlaces: 2, // optional, defaults to 2dp
            color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            style: {
              borderRadius: 16,
            },
            propsForDots: {
              r: "6",
              strokeWidth: "2",
              stroke: "#ffa726",
            },
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#313F49",
    alignItems: "center",
  },
  textStyle: {
    color: "white",
  },
});
